<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text ml-1" style="color: #FFFFFF">
          {{ $t('Global.Name') }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-0">
        <BImg class="bg" :src="require('@/assets/images/logo/login.png')" />
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t('Auth.Login.WelcomeText') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Auth.Login.LoginInvitation') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- username -->
              <b-form-group
                :label="$t('Auth.Login.Username')"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Auth.Login.Username')"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Auth.Login.UsernameExample')"
                    name="login-username"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="$t('Auth.Login.Password')"
                label-for="login-password"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Auth.Login.Password')"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                      name="login-password"
                      class="form-control-merge"
                      placeholder="············"
                      autocomplete="off"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-alert v-height-fade.appear variant="danger" :show="hasError">
                <div class="alert-body">
                  <feather-icon icon="InfoIcon" class="mr-50" />
                  {{ $t('Auth.Login.Error') }}
                </div>
              </b-alert>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                {{ $t('Auth.Login.Login') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import useJwt from '@/auth/jwt/useJwt'
import { updateAbility } from '@/libs/acl/ability'
import { RolesEnum } from '@/data/enums'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'height-fade': heightFade,
  },
  mixins: [togglePasswordVisibility],
  data: () => ({
    password: '',
    username: '',
    hasError: false,

    required,
  }),
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async validationForm() {
      const validation = await this.$refs.loginValidation.validate()

      if (!validation) return

      try {
        const { data } = await useJwt.login({
          username: this.username,
          password: this.password,
        })
        const { accessToken, user, refreshToken } = data

        if (user.role === RolesEnum.Employee) throw new Error()

        useJwt.setToken(accessToken)
        useJwt.setRefreshToken(refreshToken)
        localStorage.setItem('user', JSON.stringify(user))
        this.$ability.update(updateAbility(user.role))

        this.$router.replace({ name: 'home' })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Auth.Login.Welcome', {
              name:
                user.firstname && user.lastname
                  ? `${user.firstname} ${user.lastname}`
                  : user.username,
            }),
            icon: 'CoffeeIcon',
            text: this.$t('Auth.Login.WelcomeSuccess', {
              role: this.$t(`Auth.Roles.${user.role}`),
            }),
          },
        })
      } catch {
        this.hasError = true

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Auth.Login.LoginError'),
            icon: 'AlertTriangleIcon',
            text: this.$t('Auth.Login.Error'),
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.bg {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
</style>
